import { AuthContext } from 'context/AuthProvider';
import CSPContext from 'context/CSPListingsProvider';
import EventsContext from 'context/EventsListingsProvider';
import KnightsContext from 'context/KnightsListingsProvider';
import OfferingsContext from 'context/OfferingsListingsProvider';
import PartnersContext from 'context/PartnerListingsProvider';
import UseCasesContext from 'context/UseCasesListingsProvider';

import { useContext } from 'react';

export function useAuthContext() {
  return useContext(AuthContext);
}

export function useEventsContext() {
  return useContext(EventsContext);
}

export function useKnightsContext() {
  return useContext(KnightsContext);
}

export function useOfferingContext() {
  return useContext(OfferingsContext);
}

export function usePartnerContext() {
  return useContext(PartnersContext);
}

export function useUseCasesContext() {
  return useContext(UseCasesContext);
}
export function useCSPContext() {
  return useContext(CSPContext);
}
