'use client';
import {
  useCSPContext,
  useEventsContext,
  useKnightsContext,
  useOfferingContext,
  usePartnerContext,
  useUseCasesContext
} from 'hooks/useContexts';
import FinderToolPage from 'componentsV2/organisms/FinderToolPage';
import { useEffect, useState } from 'react';
import { PartnerFinderController } from 'controllers/finderTools/PartnerFinder';
import { MarketplaceFinderController } from 'controllers/finderTools/MarketplaceFinder';
import { KnightsFinderController } from 'controllers/finderTools/KnightsFinder';
import { EventsFinderController } from 'controllers/finderTools/EventsFinder';
import { UseCasesFinderController } from 'controllers/finderTools/UseCasesFinder';
import { setLocalStorageItem } from 'lib/localStorage';
import {
  deSerializeFromUrl,
  parseFilters,
  serializeToUrl
} from 'shared/finderTools';
import { createUrl } from 'lib/utils';
import { getLandingPageResources } from 'services/landingPage';
import { CSPFinderController } from 'controllers/finderTools/CSPFinder';
import useRouterStuff from 'hooks/useRouterStuff';

export type LandingPageResources = {
  locations?: any[];
  products?: any[];
  services?: any[];
  languages?: any[];
  countries?: any[];
  accreditations?: any[];
  verticals?: any[];
  businessUnits?: any[];
  formFactors?: any[];
  operatingSystems?: any[];
  prices?: any[];
  categories?: any[];
  eventTypes?: any[];
  knightTypes?: any[];
  divisions?: any[];
  providerServices?: any[];
  servicesProvided?: any[];
  verticalMarket?: any[];
  regions?: any[];
  useCaseType?: any[];
  readerType?: any[];
  isLoading: boolean;
};

type TabType =
  | 'partner'
  | 'marketplace'
  | 'knights'
  | 'events'
  | 'useCases'
  | 'cloud_svcs';

export default function LandingPage({
  tab
}: {
  tab:
    | 'partner'
    | 'marketplace'
    | 'knights'
    | 'events'
    | 'cloud_svcs'
    | 'useCases';
}) {
  const { router, pathname, searchParams: params } = useRouterStuff();

  const filtersResources: LandingPageResources = getLandingPageResources();

  const {
    isFirstWebsiteVisit,
    setFirstWebsiteVisit,
    setWhereToComeBack,
    filters: filtersPartnerFinder = {},
    setFilters: setPartnerFinderFilters,
    validPartnerFinderFilters
  } = usePartnerContext();

  const {
    filters: filtersMarketplace = {},
    setFilters: setMarketplaceFilters,
    validMarketplaceFinderFilters
  } = useOfferingContext();

  const {
    filters: filtersKnights = {},
    setFilters: setKnightsFilters,
    validKnightsFinderFilters
  } = useKnightsContext();

  const {
    filters: filtersUseCases = {},
    setFilters: setUseCasesFilters,
    validUseCasesFinderFilters
  } = useUseCasesContext();

  const {
    filters: filtersEvents = {},
    setFilters: setEventsFilters,
    validEventsFinderFilters
  } = useEventsContext();

  const {
    filters: filtersCSP = {},
    setFilters: setCSPFilters,
    validCSPFinderFilters
  } = useCSPContext();

  const partnerFinderUtils = PartnerFinderController();
  const marketplaceFinderUtils = MarketplaceFinderController();
  const knightsFinderUtils = KnightsFinderController();
  const eventsFinderUtils = EventsFinderController();
  const useCasesFinderUtils = UseCasesFinderController();
  const cspFinderUtils = CSPFinderController();

  const addToRouter = ({ filters, validFilters }) => {
    const params =
      Object.keys(filters)?.filter((el) => validFilters?.includes(el)).length >
      0
        ? serializeToUrl(parseFilters(filters))
        : '';

    const searchParams = new URLSearchParams(params);
    const url = createUrl(pathname || '/', searchParams);
    router.push(url);
  };

  useEffect(() => {
    if (tab === 'partner')
      addToRouter({
        filters: filtersPartnerFinder,
        validFilters: validPartnerFinderFilters
      });
  }, [filtersPartnerFinder, tab]);

  useEffect(() => {
    if (tab === 'marketplace')
      addToRouter({
        filters: filtersMarketplace,
        validFilters: validMarketplaceFinderFilters
      });
  }, [filtersMarketplace, tab]);

  useEffect(() => {
    if (tab === 'knights')
      addToRouter({
        filters: filtersKnights,
        validFilters: validKnightsFinderFilters
      });
  }, [filtersKnights, tab]);

  useEffect(() => {
    if (tab === 'events')
      addToRouter({
        filters: filtersEvents,
        validFilters: validEventsFinderFilters
      });
  }, [filtersEvents, tab]);

  useEffect(() => {
    if (tab === 'cloud_svcs')
      addToRouter({
        filters: filtersCSP,
        validFilters: validCSPFinderFilters
      });
  }, [filtersCSP, tab]);

  useEffect(() => {
    if (tab === 'useCases')
      addToRouter({
        filters: filtersUseCases,
        validFilters: validUseCasesFinderFilters
      });
  }, [filtersUseCases, tab]);

  // Dismiss black background after clicking anywhere
  const handleClickFirstVisit = () => {
    if (isFirstWebsiteVisit) {
      setLocalStorageItem('isFirstWebsiteVisit', false);
      setFirstWebsiteVisit(false);
    }
  };

  useEffect(() => {
    setWhereToComeBack({
      standalone: false,
      partnerFinder: true
    });

    const searchParams = {};
    params?.forEach((value, key) => (searchParams[key] = value));

    const { filtersObj: filterDes } = deSerializeFromUrl(searchParams);
    if (tab === 'marketplace') {
      if (Object.keys(filtersMarketplace).length === 0) {
        setMarketplaceFilters(filterDes);
      }
    } else if (tab === 'partner') {
      if (Object.keys(filtersPartnerFinder).length === 0) {
        setPartnerFinderFilters(filterDes);
      }
    } else if (tab === 'knights') {
      if (Object.keys(filtersKnights).length === 0) {
        setKnightsFilters(filterDes);
      }
    } else if (tab === 'cloud_svcs') {
      if (Object.keys(filtersCSP).length === 0) {
        setCSPFilters(filterDes);
      }
    } else if (tab === 'events') {
      if (Object.keys(filtersEvents).length === 0) {
        setEventsFilters(filterDes);
      }
    } else if (tab === 'useCases') {
      if (Object.keys(filtersUseCases).length === 0) {
        setUseCasesFilters(filterDes);
      }
    }
  }, []);

  return (
    <>
      {isFirstWebsiteVisit && (
        <div
          className="fixed bg-[#4c4d4f] opacity-50 h-screen w-screen top-0 left-0 z-[100] overflow-hidden"
          onClick={() => handleClickFirstVisit()}
        />
      )}
      {tab === 'partner' ? (
        <FinderToolPage
          tab="partner"
          key={'partner_finder_tool'}
          filtersResources={filtersResources}
          partnerFinder={partnerFinderUtils}
        />
      ) : tab === 'marketplace' ? (
        <FinderToolPage
          tab="marketplace"
          key={'marketplace_finder_tool'}
          filtersResources={filtersResources}
          marketplaceFinder={marketplaceFinderUtils}
        />
      ) : tab === 'knights' ? (
        <FinderToolPage
          tab="knights"
          key={'knights_finder_tool'}
          filtersResources={filtersResources}
          knightsFinder={knightsFinderUtils}
        />
      ) : tab === 'events' ? (
        <FinderToolPage
          tab="events"
          key={'events_finder_tool'}
          filtersResources={filtersResources}
          eventsFinder={eventsFinderUtils}
        />
      ) : tab === 'cloud_svcs' ? (
        <FinderToolPage
          tab="cloud_svcs"
          key={'csp_finder_tool'}
          filtersResources={filtersResources}
          cspFinder={cspFinderUtils}
        />
      ) : tab === 'useCases' ? (
        <FinderToolPage
          tab="useCases"
          key={'useCases_finder_tool'}
          filtersResources={filtersResources}
          useCasesFinder={useCasesFinderUtils}
        />
      ) : null}
    </>
  );
}
