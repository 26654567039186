import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';
import { cn } from 'lib/utils';
import { buttonVariants } from './button';
import { ComponentProps } from 'react';
import { es, enUS, pt, ja, zhCN } from 'date-fns/locale';
import { useEventsContext, usePartnerContext } from 'hooks/useContexts';
import { getNewDate } from 'shared/utils';

export type CalendarProps = ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const { language } = usePartnerContext();
  const { usersTimezoneLocation } = useEventsContext();

  const eventDates = (props as any)?.eventData?.map((el) => el.startDate);
  const onSelect = (props as any)?.onSelect;
  const setCurrentDateMonth = (props as any)?.setCurrentDateMonth;
  const selected = (props as any)?.selected;
  const currentDateMonth = (props as any)?.currentDateMonth;

  const isToday = (date: Date) => {
    const today = getNewDate({
      date: new Date(),
      isUTC0: true,
      returnDateObject: true,
      getCurrentDateAndTime: true
    });
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isDaySelected = (date: Date) => {
    const selectedDate = getNewDate({
      date: selected,
      getDateObjectFromUsersTimezone: true,
      usersTimezoneLocation,
      removeTimezone: true
    });
    if (selectedDate) {
      return (
        date.getDate() === selectedDate.getDate() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getFullYear() === selectedDate.getFullYear()
      );
    }
  };

  const isDayOutsideOfMonth = (date: Date) => {
    if (currentDateMonth) {
      return (
        date.getMonth() !== currentDateMonth.getMonth() ||
        date.getFullYear() !== currentDateMonth.getFullYear()
      );
    }
  };

  const getDayFromDate = (date: Date) => {
    return getNewDate({
      date,
      usersTimezoneLocation,
      getISODateFromUsersTimezone: true
    })
      ?.split('T')[0]
      ?.split('-')[2];
  };

  return (
    <DayPicker
      month={eventDates?.length > 0 ? currentDateMonth : props?.month}
      locale={
        language === 'en' || language === 'en-US' || language === 'en-GB'
          ? enUS
          : language === 'ja'
          ? ja
          : language === 'cn'
          ? zhCN
          : language === 'pt'
          ? pt
          : language === 'es'
          ? es
          : enUS
      }
      showOutsideDays={showOutsideDays}
      className={cn('p-4', className)}
      classNames={{
        month: 'flex flex-col gap-2 text-white',
        caption: 'flex justify-center relative items-center',
        caption_label: 'text-base font-normal text-black',
        nav: 'flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-7 w-7 bg-transparent p-0 text-[#2360A0]'
        ),
        nav_button_previous:
          'absolute left-0 disabled:pointer-events-auto disabled:cursor-not-allowed',
        nav_button_next: 'absolute right-0',
        table: 'w-full border-collapse',
        head_row: 'flex gap-1',
        head_cell: 'text-[#2360A0] w-9 font-light text-sm',
        row: 'flex gap-1 w-full mt-2',
        cell: 'h-9 w-9 text-center rounded-[4px] text-sm p-0 relative focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 text-[#53565A] hover:bg-[#2360A0] hover:text-white rounded-[4px] text-sm font-normal aria'
        ),
        day_range_end: 'day-range-end',
        day_selected:
          'aria-selected:bg-[#2360A0] aria-selected:text-white aria-selected:hover:bg-[#2360A0] aria-selected:hover:text-white',
        day_today:
          '!border border-solid border-[#2360A0] aria-selected:border-[#2360A0]',
        day_outside: 'day-outside text-[#92929D] !font-light',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:!bg-[#ECF1F6] aria-selected:!text-accent-foreground aria-selected:font-light',
        day_hidden: 'invisible',
        ...classNames
      }}
      components={
        eventDates?.length > 0
          ? {
              IconLeft: () => <ChevronLeft className="h-4 w-4" />,
              IconRight: () => <ChevronRight className="h-4 w-4" />,
              Day: (dayDate) => (
                <div
                  className="relative"
                  onClick={() => {
                    onSelect && onSelect(dayDate.date);
                    setCurrentDateMonth && setCurrentDateMonth(dayDate.date);
                  }}
                >
                  <div
                    className={
                      isToday(dayDate.date)
                        ? cn(
                            buttonVariants({ variant: 'ghost' }),
                            'h-9 w-9 p-0 m-0 !border border-solid border-[#2360A0] aria-selected:border-[#2360A0] rounded-[4px] text-xs font-normal'
                          )
                        : isDaySelected(dayDate.date)
                        ? cn(
                            buttonVariants({ variant: 'ghost' }),
                            'h-9 w-9 p-0 m-0 bg-[#2360A0] text-white hover:bg-[#2360A0] hover:text-white rounded-[4px] text-xs font-normal'
                          )
                        : isDayOutsideOfMonth(dayDate.date)
                        ? cn(
                            buttonVariants({ variant: 'ghost' }),
                            'h-9 w-9 p-0 day-outside text-[#b5bfc9] rounded-[4px] text-xs font-normal'
                          )
                        : cn(
                            buttonVariants({ variant: 'ghost' }),
                            'h-9 w-9 p-0 text-[#53565A] hover:bg-[#2360A0] hover:text-white rounded-[4px] text-xs font-normal'
                          )
                    }
                  >
                    {getDayFromDate(dayDate.date)}
                  </div>
                  {eventDates?.find(
                    (el) =>
                      el?.split('T')[0] ===
                      getNewDate({
                        date: dayDate.date,
                        getISODateFromUsersTimezone: true,
                        usersTimezoneLocation,
                        removeTimezone: true
                      })?.split('T')[0]
                  ) && (
                    <div className="absolute top-[5px] right-[5px] w-[5px] h-[5px] rounded-[4px] bg-red-600"></div>
                  )}
                </div>
              )
            }
          : {
              IconLeft: () => <ChevronLeft className="h-4 w-4" />,
              IconRight: () => <ChevronRight className="h-4 w-4" />
            }
      }
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
