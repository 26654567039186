'use client';
import { useEffect } from 'react';
import styles from './style.module.scss';
import useScroll from 'hooks/useScroll';

export default function Loader({ noScroll = false }) {
  const [blockScroll, allowScroll] = useScroll();
  useEffect(() => {
    !noScroll && window.scrollTo({ top: 0, behavior: 'smooth' });
    blockScroll();
    return function cleanup() {
      allowScroll();
    };
  }, []);
  return (
    <div className={styles.loader}>
      <div className={styles.icon_container} />
    </div>
  );
}
