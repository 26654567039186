import useDevice from 'hooks/useDevice';
import { usePartnerContext } from 'hooks/useContexts';
import { useQuery } from '@tanstack/react-query';
import { api } from 'services/api';
import { staleTime } from 'utils/caching';
import {
  TQueryData,
  defaultQueryData,
  paginateResults,
  shuffle
} from './utils';
import { AxiosResponse } from 'axios';

const PARTNERS_PER_PAGE = 12;

export type PartnerFinderControllerResult = {
  totalPartners: number;
  totalRelatedOfferings: number;
  relatedOfferings: any[];
  currentPartners: any[];
  PARTNERS_PER_PAGE: number;
  RELATED_OFFERINGS_PER_PAGE: number;
  isLoading: boolean;
  isLoadingRelated: boolean;
};

export function PartnerFinderController(): PartnerFinderControllerResult {
  const { width } = useDevice();
  const { filters, currentPageFiltering, relatedPageFiltering } =
    usePartnerContext();

  const RELATED_OFFERINGS_PER_PAGE = width > 2000 ? 4 : width < 1201 ? 2 : 3;

  const queryString = `${new URLSearchParams(filters).toString()}`;

  const {
    data: partnersQuery = defaultQueryData,
    isLoading: isLoading,
    status
  } = useQuery<TQueryData>({
    queryKey: ['partnersByFilters-partnerFinder', { filters: queryString }],
    queryFn: async () => {
      const res: AxiosResponse<TQueryData> = await api.get(
        `/finderTool/partners?${queryString}`
      );
      return res.data;
    },
    enabled: queryString.length > 0,
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const { data: partnersResult = [], isLoading: isLoadingResult } = useQuery<
    any[]
  >({
    queryKey: [
      'paginatedPartnerResults-partnerFinder',
      partnersQuery,
      { page: currentPageFiltering, per_page: PARTNERS_PER_PAGE }
    ],
    queryFn: async () => {
      const paginatedPartnerIds = paginateResults(
        partnersQuery.data,
        currentPageFiltering,
        PARTNERS_PER_PAGE
      );
      const res: AxiosResponse<any[]> = await api.get(
        `/finderTool/partners/specifics?specific_ids=${paginatedPartnerIds}`
      );

      return res.data;
    },
    enabled:
      queryString.length > 0 &&
      !!partnersQuery.data &&
      partnersQuery.data.length > 0 &&
      status !== 'pending',
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const {
    data: offeringsQuery = defaultQueryData,
    isLoading: isLoadingRelated,
    status: relatedStatus
  } = useQuery<TQueryData>({
    queryKey: ['relatedsByFilters-partnerFinder', { filters: queryString }],
    queryFn: async () => {
      const res: AxiosResponse<TQueryData> = await api.get(
        `/finderTool/partners/related?${queryString}`
      );
      return res.data;
    },
    enabled: queryString.length > 0,
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const { data: offeringsResults = [], isLoading: isLoadingRelatedResult } =
    useQuery<any[]>({
      queryKey: [
        'paginatedRelatedResults-partnerFinder',
        offeringsQuery,
        { page: relatedPageFiltering, per_page: RELATED_OFFERINGS_PER_PAGE }
      ],
      queryFn: async () => {
        const paginatedOfferingIds = paginateResults(
          offeringsQuery.data,
          relatedPageFiltering,
          RELATED_OFFERINGS_PER_PAGE
        );
        const res: AxiosResponse<any[]> = await api.get(
          `/finderTool/marketplace/specifics?specific_ids=${paginatedOfferingIds}`
        );

        return res.data;
      },
      enabled:
        queryString.length > 0 &&
        !!offeringsQuery.data &&
        offeringsQuery.data.length > 0 &&
        relatedStatus !== 'pending',
      refetchOnWindowFocus: false,
      staleTime: staleTime
    });

  return {
    currentPartners: partnersResult || [],
    totalPartners: partnersQuery?.totalCount || 0,
    PARTNERS_PER_PAGE: PARTNERS_PER_PAGE,
    isLoading: isLoading || isLoadingResult,
    relatedOfferings: offeringsResults || [],
    totalRelatedOfferings: offeringsQuery?.totalCount || 0,
    RELATED_OFFERINGS_PER_PAGE: RELATED_OFFERINGS_PER_PAGE,
    isLoadingRelated: isLoadingRelated || isLoadingRelatedResult
  };
}
