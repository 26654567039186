import { useQuery } from '@tanstack/react-query';
import { useCSPContext } from 'hooks/useContexts';
import { api } from 'services/api';
import { staleTime } from 'utils/caching';
import { TQueryData, defaultQueryData, paginateResults } from './utils';
import { AxiosResponse } from 'axios';

export type CSPFinderControllerResult = {
  totalProviders: number;
  currentProviders: any[];
  PROVIDERS_PER_PAGE: number;
  isLoading: boolean;
};

export function CSPFinderController(): CSPFinderControllerResult {
  const { filters, currentPageFiltering, randomized, setRandomized } =
    useCSPContext();

  const PROVIDERS_PER_PAGE = 12;

  const queryString = `${new URLSearchParams(filters).toString()}`;

  const {
    data: providersQuery = defaultQueryData,
    isLoading: isLoading,
    status
  } = useQuery<TQueryData>({
    queryKey: ['providersByFilters-providersFinder', { filters: queryString }],
    queryFn: async () => {
      const res: AxiosResponse<TQueryData> = await api.get(
        `/finderTool/provider?${queryString}`
      );
      return res.data;
    },
    enabled: queryString.length > 0,
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const { data: providersResults = [], isLoading: isLoadingResult } = useQuery<
    any[]
  >({
    queryKey: [
      'paginatedProviderResults-providersFinder',
      providersQuery,
      { page: currentPageFiltering, per_page: PROVIDERS_PER_PAGE }
    ],
    queryFn: async () => {
      const paginatedProviderIds = paginateResults(
        providersQuery.data,
        currentPageFiltering,
        PROVIDERS_PER_PAGE
      );
      const res: AxiosResponse<any[]> = await api.get(
        `/finderTool/provider/specifics?specific_ids=${paginatedProviderIds}`
      );

      return res.data;
    },
    enabled:
      queryString.length > 0 &&
      !!providersQuery.data &&
      providersQuery.data.length > 0 &&
      status !== 'pending',
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  return {
    totalProviders: providersQuery?.totalCount || 0,
    currentProviders: providersResults,
    PROVIDERS_PER_PAGE: PROVIDERS_PER_PAGE,
    isLoading: isLoading || isLoadingResult
  };
}
