import useDevice from 'hooks/useDevice';
import { useQuery } from '@tanstack/react-query';
import { useUseCasesContext } from 'hooks/useContexts';
import { staleTime } from 'utils/caching';
import { api } from 'services/api';
import { TQueryData, defaultQueryData, paginateResults } from './utils';
import { AxiosResponse } from 'axios';

export type UseCasesFinderControllerResult = {
  totalUseCases: number;
  totalRelatedPartners: number;
  currentUseCases: any[];
  relatedPartnersFromPage: any[];
  USECASES_PER_PAGE: number;
  RELATED_PARTNERS_PER_PAGE: number;
  isLoading: boolean;
  isLoadingRelated: boolean;
};

export function UseCasesFinderController(): UseCasesFinderControllerResult {
  const { width } = useDevice();
  const { filters, currentPageFiltering, relatedPageFiltering } =
    useUseCasesContext();

  const USECASES_PER_PAGE = 12;
  const RELATED_PARTNERS_PER_PAGE = width > 2000 ? 4 : width < 1201 ? 2 : 3;

  const queryString = `${new URLSearchParams(filters).toString()}`;

  const {
    data: useCasesQuery = defaultQueryData,
    isLoading: isLoading,
    status
  } = useQuery<TQueryData>({
    queryKey: ['useCasesByFilters-useCasesFinder', { filters: queryString }],
    queryFn: async () => {
      const res: AxiosResponse<TQueryData> = await api.get(
        `/finderTool/useCases?${queryString}`
      );
      return res.data;
    },
    enabled: queryString.length > 0,
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const { data: useCasesResults = [], isLoading: isLoadingResult } = useQuery<
    any[]
  >({
    queryKey: [
      'paginatedUseCasesResults-useCasesFinder',
      useCasesQuery,
      { page: currentPageFiltering, per_page: USECASES_PER_PAGE }
    ],
    queryFn: async () => {
      const paginatedUseCasesIds = paginateResults(
        useCasesQuery.data,
        currentPageFiltering,
        USECASES_PER_PAGE
      );
      const res: AxiosResponse<any[]> = await api.get(
        `/finderTool/useCases/specifics?specific_ids=${paginatedUseCasesIds}`
      );

      return res.data;
    },
    enabled:
      queryString.length > 0 &&
      !!useCasesQuery.data &&
      useCasesQuery.data.length > 0 &&
      status !== 'pending',
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const {
    data: partnersQuery = defaultQueryData,
    isLoading: isLoadingRelated,
    status: relatedStatus
  } = useQuery<TQueryData>({
    queryKey: ['relatedsByFilters-useCasesFinder', { filters: queryString }],
    queryFn: async () => {
      const res: AxiosResponse<TQueryData> = await api.get(
        `/finderTool/useCases/related?${queryString}`
      );
      return res.data;
    },
    enabled: queryString.length > 0,
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const { data: partnersResults = [], isLoading: isLoadingRelatedResult } =
    useQuery<any[]>({
      queryKey: [
        'paginatedRelatedResults-useCasesFinder',
        partnersQuery,
        { page: relatedPageFiltering, per_page: RELATED_PARTNERS_PER_PAGE }
      ],
      queryFn: async () => {
        const paginatedPartnerIds = paginateResults(
          partnersQuery.data,
          relatedPageFiltering,
          RELATED_PARTNERS_PER_PAGE
        );
        const res: AxiosResponse<any[]> = await api.get(
          `/finderTool/partners/specifics?specific_ids=${paginatedPartnerIds}`
        );

        return res.data;
      },
      enabled:
        queryString.length > 0 &&
        !!partnersQuery.data &&
        partnersQuery.data.length > 0 &&
        relatedStatus !== 'pending',
      refetchOnWindowFocus: false,
      staleTime: staleTime
    });

  return {
    currentUseCases: useCasesResults,
    totalUseCases: useCasesQuery.totalCount || 0,
    USECASES_PER_PAGE: USECASES_PER_PAGE,
    isLoading: isLoading || isLoadingResult,
    relatedPartnersFromPage: partnersResults,
    totalRelatedPartners: partnersQuery.totalCount || 0,
    RELATED_PARTNERS_PER_PAGE: RELATED_PARTNERS_PER_PAGE,
    isLoadingRelated: isLoadingRelated || isLoadingRelatedResult
  };
}
