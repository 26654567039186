export const getLocalStorageItem = (key: string) => {
  if (typeof window !== 'undefined') return localStorage.getItem(key);
  else return null;
};

export const setLocalStorageItem = (key: string, value: any) => {
  if (typeof window !== 'undefined') return localStorage.setItem(key, value);
  else return null;
};

export const removeLocalStorageItem = (key: string) => {
  if (typeof window !== 'undefined') return localStorage.removeItem(key);
  else return null;
};
