import useDevice from 'hooks/useDevice';
import { useQuery } from '@tanstack/react-query';
import { useEventsContext } from 'hooks/useContexts';
import { staleTime } from 'utils/caching';
import { api } from 'services/api';
import { TQueryData, defaultQueryData, paginateResults } from './utils';
import { AxiosResponse } from 'axios';

export type EventsFinderControllerResult = {
  totalEvents: number;
  currentEvents: any[];
  totalRelatedPartners: number;
  relatedPartnersFromPage: any[];
  isLoading: boolean;
  EVENTS_PER_PAGE: number;
  RELATED_PARTNERS_PER_PAGE: number;
  isLoadingRelated: boolean;
};

export function EventsFinderController(): EventsFinderControllerResult {
  const { filters, currentPageFiltering, relatedPageFiltering } =
    useEventsContext();

  const { width } = useDevice();

  const EVENTS_PER_PAGE = 12;
  const RELATED_PARTNERS_PER_PAGE = width > 2000 ? 4 : width < 1201 ? 2 : 3;

  const queryString = `${new URLSearchParams(filters).toString()}`;

  const {
    data: eventsQuery = defaultQueryData,
    isLoading: isLoading,
    status
  } = useQuery<TQueryData>({
    queryKey: ['eventsByFilters-eventsFinder', { filters: queryString }],
    queryFn: async () => {
      const res: AxiosResponse<TQueryData> = await api.get(
        `/finderTool/events?${queryString}`
      );
      return res.data;
    },
    enabled: queryString.length > 0,
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const { data: eventsResults = [], isLoading: isLoadingResult } = useQuery<
    any[]
  >({
    queryKey: [
      'paginatedEventResults-eventsFinder',
      eventsQuery,
      { page: currentPageFiltering, per_page: EVENTS_PER_PAGE }
    ],
    queryFn: async () => {
      const paginatedEventIds = paginateResults(
        eventsQuery.data,
        currentPageFiltering,
        EVENTS_PER_PAGE
      );
      const res: AxiosResponse<any[]> = await api.get(
        `/finderTool/events/specifics?specific_ids=${paginatedEventIds}`
      );

      return res.data;
    },
    enabled:
      queryString.length > 0 &&
      !!eventsQuery.data &&
      eventsQuery.data.length > 0 &&
      status !== 'pending',
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const {
    data: partnersQuery = defaultQueryData,
    isLoading: isLoadingRelated,
    status: relatedStatus
  } = useQuery<TQueryData>({
    queryKey: ['relatedsByFilters-eventsFinder', { filters: queryString }],
    queryFn: async () => {
      const res: AxiosResponse<TQueryData> = await api.get(
        `/finderTool/events/related?${queryString}`
      );
      return res.data;
    },
    enabled: queryString.length > 0,
    refetchOnWindowFocus: false,
    staleTime: staleTime
  });

  const { data: partnersResults = [], isLoading: isLoadingRelatedResult } =
    useQuery<any[]>({
      queryKey: [
        'paginatedRelatedResults-eventsFinder',
        partnersQuery,
        { page: relatedPageFiltering, per_page: RELATED_PARTNERS_PER_PAGE }
      ],
      queryFn: async () => {
        const paginatedPartnerIds = paginateResults(
          partnersQuery.data,
          relatedPageFiltering,
          RELATED_PARTNERS_PER_PAGE
        );
        const res: AxiosResponse<any[]> = await api.get(
          `/finderTool/partners/specifics?specific_ids=${paginatedPartnerIds}`
        );

        return res.data;
      },
      enabled:
        queryString.length > 0 &&
        !!partnersQuery.data &&
        partnersQuery.data.length > 0 &&
        relatedStatus !== 'pending',
      refetchOnWindowFocus: false,
      staleTime: staleTime
    });

  return {
    totalEvents: eventsQuery?.totalCount,
    currentEvents: eventsResults,
    totalRelatedPartners: partnersQuery?.totalCount,
    relatedPartnersFromPage: partnersResults,
    isLoading: isLoading || isLoadingResult,
    EVENTS_PER_PAGE: EVENTS_PER_PAGE,
    RELATED_PARTNERS_PER_PAGE: RELATED_PARTNERS_PER_PAGE,
    isLoadingRelated: isLoadingRelated || isLoadingRelatedResult
  };
}
