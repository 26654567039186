export const SITE_ADMINISTRATION_TABS = {
  certifications: 'certifications',
  customersCompany: 'customersCompany',
  events: 'events',
  marketplace: 'marketplace',
  quarters: 'quarters',
  roles: 'roles',
  searchTags: 'searchTags',
  surveyLog: 'surveyLog',
  services: 'services',
  useCases: 'useCases',
  resources: 'resources'
};

export const OLD_SITE_ADMINISTRATION_PAGE_URL = 'old-site-administration';
export const SITE_ADMINISTRATION_PAGE_URL = 'site-administration';
export const DASHBOARD_PAGE_URL = 'dashboard';
export const SITE_ADMINISTRATION_DEFAULT_TAB =
  SITE_ADMINISTRATION_TABS.marketplace;

export const PROTECTEC_ROUTES = [
  `/${SITE_ADMINISTRATION_PAGE_URL}`,
  `/${DASHBOARD_PAGE_URL}`,
  `/${OLD_SITE_ADMINISTRATION_PAGE_URL}`
];

export const PADS_LIST = [
  'Gradian Systems Ltd',
  'E3',
  'itdesign',
  'Tricise',
  'HCLTech',
  'Apiida',
  'Extra Technology',
  'Robert Mark Technologies',
  'Rego Consulting',
  'Infolock',
  'Trum & Associates',
  'A&I Solutions',
  'Optovia',
  'Interlan SAS'
];
